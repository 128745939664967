import React from "react";
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import Dashboard from './pages/dashboard.js';
import LoginPage from './pages/login.js';
import { PrivateRoute, ProvideAuth } from "./utils/auth.js";

export default function htw() {
  return (
    <ProvideAuth>
      <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="*">
              <Redirect to="/dashboard/president" />
            </Route>
          </Switch>
      </Router>
    </ProvideAuth>
  );
}

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */




/*
function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  return auth.user ? (
    <p>
      Welcome!{" "}
      <button
        onClick={() => {
          auth.signout(() => history.push("/"));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}

function PublicPage() {
  return <h3>Public</h3>;
}


*/
