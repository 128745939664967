import { Box, Button, Center, Heading, Input, Stack, useToast } from "@chakra-ui/react";
import axios from 'axios';
import React, { useState } from 'react';
import {
    useHistory
} from "react-router-dom";
import { useAuth } from "../utils/auth.js";

var LoginPage = function (props) {
    let history = useHistory();
    let auth = useAuth();
    const [password, setPassword] = useState('');
    const toast = useToast();
    const [failCount, setFailCount] = useState(0);
  
    let login = () => {
        let urlstring = process.env.NODE_ENV === 'production' ? 'https://'+process.env.REACT_APP_BACKEND+'login' : 'http://'+process.env.REACT_APP_BACKEND+'getVotes'
        axios.post(urlstring,  {'password': password} ).then( (response) => {
            auth.setToken(response.data)
            history.push('/dashboard/president')
        }).catch( (e) => {
            setFailCount(failCount+1)
            if (failCount<2) {
                toast({
                    title: "Invalid password.",
                    status: "error",
                    duration: 9000,
                    position: 'top',
                    isClosable: true,
                  })
            } else {
                toast({
                    title: "Too many failures. Try again later.",
                    status: "error",
                    duration: 9000,
                    position: 'top',
                    isClosable: true,
                  })
            }
        })
    };
    let _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          login()
        }
    }
  
    return (
    <Center h="100vh" w="100vw" bgGradient="linear(to-r, red.500, blue.500)">
    <Box padding="24px">
        <Stack spacing="24px">
        <Heading color="white" size="2xl"> HOW'D THEY WIN? </Heading>
        <Center>
        <Stack spacing="12px"width="300px"> 
            <Input color="white" bg="rgba(255,255,255,.1)" borderColor="rgba(255,255,255,.1)" placeholder="Password" type="password"
            _placeholder ={{color:'white'}} onChange={e => setPassword(e.target.value)} onKeyDown={_handleKeyDown} isDisabled={failCount<3 ? false : true} /> 
            <Button
                onClick={login}
                colorScheme="white"
                bg="rgba(255,255,255,.1)"
                aria-label="Login"
                isDisabled={failCount<3 ? false : true}
                >LOGIN</Button>
        </Stack>
        </Center>
        </Stack>
    </Box>
    </Center>
        
    );
}
export default LoginPage