import { Center, Flex, Grid, GridItem, useToast } from "@chakra-ui/react";
import React, {useEffect} from 'react';
import {
    Redirect, Route, Switch, useHistory, useRouteMatch
} from "react-router-dom";
import CountDem from './countdem.js';
import CountDNV from './countdnv.js';
import Header from './header.js';
import ProductTable from './producttable.js';

var Dashboard = function (props) {
    let history = useHistory();
    let { path } = useRouteMatch();
    const toast = useToast();
    useEffect(()=>{
        console.log('testa')
        console.log(localStorage.getItem('shownHint'))
        console.log(typeof localStorage.getItem('shownHint'))
        if (localStorage.getItem('shownHint') == null) {
            console.log('yo')
            setTimeout(() => {toast({
                title: "Click the gear in the top right to filter to CDs or LDs.",
                status: "info",
                duration: 7000,
                position: 'top',
                isClosable: true,
              }) 
              localStorage.setItem('shownHint', 'yessirrrr')},4000)

        }
    }, [toast])
    return (
        <Flex 
        flexFlow="column"
        h="100vh"
        w="100vw">
        <Header curpath = {history.location.pathname} />
        <Switch>
            <Route exact path={path}>
                <Redirect to="/dashboard/president" />
            </Route>
            <Route path={`${path}/:raceId`}>
            <Grid
            h="100%"
            w="100%"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            maxWidth="100%"
            overflowX="hidden"
            gap={0}
            >
                <GridItem rowSpan={2} colSpan={1} paddingTop="8px"  paddingBottom="8px"  paddingLeft="8px">
                    <Center h="100%" w="100%" order={0}>
                        <ProductTable />
                    </Center>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1} padding="8px">
                    <Center h="100%" w="100%">
                        <CountDem/>
                    </Center>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1} padding="8px">
                    <Center  h="100%" w="100%">
                        <CountDNV/>
                    </Center>
                </GridItem>
                </Grid>
            </Route>
        </Switch>
        </Flex>
    )
}

export default Dashboard

/*
                    <Center minWidth="50%" minHeight="50%" maxWidth="50%" maxHeight="50%">
                        Test
                    </Center>
                    <Center minWidth="50%" minHeight="50%" maxWidth="50%" maxHeight="50%">
                        Test
                    </Center> */