// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import React, { createContext, useContext, useState } from "react";
import {
  Redirect, Route
} from "react-router-dom";



export function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}
const authContext = createContext();
export function useAuth() {
  return useContext(authContext);
}
  
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
  

function useProvideAuth() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    try {
      return userToken
    } catch(e) {
      return ''
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    console.log('fired')
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const removeToken = () => {
    if (typeof localStorage.getItem('token') != "undefined") {
      localStorage.removeItem('token')
      //setToken(null)
    }
  }

  return {
    setToken: saveToken,
    removeToken: removeToken,
    token
  }
}