import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ChakraProvider,extendTheme } from "@chakra-ui/react"
import "@fontsource/oswald/400.css"


const theme = extendTheme({
  fonts: {
    heading: "Oswald"
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
