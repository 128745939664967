import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Box, Center, chakra, CircularProgress, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useHistory,
    useLocation,
    useParams
} from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from "../utils/auth.js";


const urlToId = {
    'president':'pres',
    'ussenate':'sen',
    'ushouse':'ush',
    'ssenate':'ssen',
    'shouse':'sh'
}

const CustomTooltip = ({ active, payload, label }) => {
    const COLOR_MAP = {
        'd': '#2580db' ,
        'r': '#990033',
        'oth': '#009c2f'
    }
    // Calculate Percentages
    if (active && payload && payload.length) {
        var sumVotes = payload.reduce((a,val) => {
            return a+val.value
        }, 0)
        var ChoiceList = payload.map( (p) => { 
            let percent = Math.round(((p.value/sumVotes)*100)).toString()+'%'
            return (
                <Text key={p.name} width="fit-content" color={COLOR_MAP[p.name]}> {`${CHOICE_MAP[p.name]}: ${toCurrency(p.value)} (${percent})`} </Text> 
            )
        })
        return (
        <Box zIndex="1000" bg="white" borderWidth="1px" borderRadius="lg" padding="12px" width="max-content" fontSize="md" >
            {ChoiceList}
        </Box>
        );
    }
    return null;
};
var VsCell = function (props) {
    let cd = props.val
    cd['x'] = 'Choices'
    let yd = {...cd}
    yd['x'] = 2
    cd = [cd]

    return (
        <BarChart
        data={cd}
        width={200}
        height={24}
        stackOffset="expand"
        layout="vertical"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <YAxis hide dataKey="x" type="category" />
            <XAxis hide type="number"/>
            <Tooltip allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{zIndex: 20}} isAnimationActive={false} formatter={tooltipFormatter} content={<CustomTooltip />}/>
            <Bar dataKey="d" stackId="a"  fill='#2580db' />
            <Bar dataKey="oth" stackId="a"  fill="#009c2f" />
            <Bar dataKey="r" stackId="a" fill='#990033' />
        </BarChart>
    )
}


const tooltipFormatter = (value, name, props) => {
    return [toCurrency(value), CHOICE_MAP[name], ]
}


const RACE_MAP = {
    'ush':'U.S. House',
    'sen': 'U.S. Senate',
    'pres': 'President',
    'ssen': 'State Senate',
    'p207': 'Proposition 207',
    'p208': 'Proposition 208'
}

const CHOICE_MAP = {
    'yes': 'Yes',
    'no': 'No',
    'dnv': 'Did Not Vote',
    'd': 'Democrat',
    'r':'Republican',
    'oth': 'Third Party/Write In'
}

var formatChoice = function (val) {
    return (<p> {CHOICE_MAP[val.value]} </p>);
}

var formatRace = function (val) {
    return ( <p> {RACE_MAP[val.value]} </p>);
}




function toCurrency(numberString) {
    let number = parseFloat(numberString);
    return number.toLocaleString('USD');
}

var ProductTable = function (props) {
    let history = useHistory();
    let { raceId } = useParams();
    const token = useAuth();
    const location = useLocation();
    const [tableData,settableData] = useState([])

    const sortVsFactory = function () {
        const sortFunc = (row1, row2) => {
            return (row1['values']['vs']['d'] /  row1['values']['total']) > (row2['values']['vs']['d'] /  row2['values']['total']) ? 1 : -1;
        }
        return sortFunc
    }
    const sortVs = useMemo(sortVsFactory,[])

    const data = React.useMemo(
    () => tableData,
    [tableData],
    )
    const columns = React.useMemo(
    () => [
        {
        Header: "Race",
        accessor: "t_race",
        Cell: formatRace,
        },
        {
        Header: "Choice",
        accessor: "t_choice",
        Cell: formatChoice,
        },
        {
        Header: "Size",
        accessor: "total",
        isNumeric: true,
        Cell: props => <div> {toCurrency(props.value)} </div>
        },
        {
        Header: "Vote Share",
        accessor: "vs",
        sortType: sortVs
        }
    ],
    [sortVs],
    )
    const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    } = useTable({ columns, data, initialState: {
        sortBy: [
            {
                id: 'vs',
                desc: true
            }
        ]
    }}, useSortBy)

    // Get table data.
    useEffect(()=>{
        let curQuery = new URLSearchParams(location.search);
        let urlstring = process.env.NODE_ENV === 'production' ? 'https://'+process.env.REACT_APP_BACKEND+'getVotes' : 'http://'+process.env.REACT_APP_BACKEND+'getVotes'
        axios.get(urlstring, {
            headers: {
                'Authorization': `Bearer ${token.token}`
            },
            params: {
                'race': urlToId[raceId],
                'cd': curQuery.has('cd') ? curQuery.get('cd') : 'all',
                'ld': curQuery.has('ld') ? curQuery.get('ld') : 'all',
                'type':'table'
            },
            })
            .then((res) => {
                console.log(res.data)
                res.data = res.data.map(d=>{
                    d['vs'] = {'d': d['d'], 'r': d['r'], 'oth': d['oth']}
                    return d
                })
                settableData(res.data.sort((a,b) => (a.count_dem > b.count_dem) ? 1 : ((b.count_dem > a.count_dem) ? -1 : 0)))
            })
            .catch((error) => {
                //token.setToken(undefined)
                if (error.response.status) {
                    token.removeToken()
                    history.push('/login')
                } else {
                    console.error(error)
                }
            })
    },[raceId,location, history,token])

    return (
        <Box h="100%" w="100%">
            {tableData ? tableData.length===0 ? 
            <Center  h="100%" w="100%"><CircularProgress isIndeterminate color="green.300" /></Center> :
            <Flex 
            flexDirection="column"
            flexGrow="1"
            flexBasis="auto"
            h="100%"
            w="100%">
            <Heading size="md" margin="12px" marginLeft="16px"> Vote Share by Choice in Other Races on Ballot</Heading>
            <Box                 flexGrow="1"
                flexBasis="auto">
                 <Table {...getTableProps()} size="sm">
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                if (column.id === 'vs') column.sortType = sortVs;
                                return (
                            <Th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                isNumeric={column.isNumeric}
                            >
                                {column.render("Header")}
                                <chakra.span pl="4">
                                {column.isSorted ? (
                                    column.isSortedDesc ? (
                                    <TriangleDownIcon aria-label="sorted descending" />
                                    ) : (
                                    <TriangleUpIcon aria-label="sorted ascending" />
                                    )
                                ) : null}
                                </chakra.span>
                            </Th>
                            )})}
                        </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                if (cell.column.id==='vs') {
                                    return ( 
                                    <Td {...cell.getCellProps()}
                                    paddingTop="0px" paddingBottom="0px"> 
                                        <VsCell {...cell.getCellProps()} test="fuck" val={cell.value} /> 
                                    </Td>
                                    )
                                } else{
                                    return (
                                    <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                                    {cell.render("Cell")}
                                    </Td>
                                    )
                                }
                            })}
                            </Tr>
                        )
                        })}
                    </Tbody>
                </Table>
            </Box></Flex> : <Center  h="100%" w="100%"><CircularProgress isIndeterminate color="green.300" /></Center>
            }
        </Box>
    )
}

export default ProductTable