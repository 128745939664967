import { SettingsIcon } from '@chakra-ui/icons';
import {
    Box, Center, Flex, Heading, HStack, IconButton, Menu,
    MenuButton, MenuDivider, MenuItemOption, MenuList, MenuOptionGroup
} from "@chakra-ui/react";
import React from 'react';
import {
    useHistory, useLocation
} from "react-router-dom";

const CDS = [
    {name: '1 (Partial)', key: '2'},
    {name: '3 (Partial)', key: '3'},
    {name: '4 (Partial)', key: '4'},
    {name: '5', key: '5'},
    {name: '6', key: '6'},
    {name: '7', key: '7'},
    {name: '8', key: '8'},
    {name: '9', key: '9'},
]

const LDS = [
    {name: '1 (Partial)', key: '11'},
    {name: '4 (Partial)', key: '12'},
    {name: '12 (Partial)', key: '13'},
    {name: '13 (Partial)', key: '14'},
    {name: '15', key: '15'},
    {name: '16 (Partial)', key: '16'},
    {name: '17', key: '17'},
    {name: '18', key: '18'},
    {name: '19', key: '19'},
    {name: '20', key: '20'},
    {name: '21', key: '21'},
    {name: '22', key: '22'},
    {name: '23', key: '23'},
    {name: '24', key: '24'},
    {name: '25', key: '25'},
    {name: '26', key: '26'},
    {name: '27', key: '27'},
    {name: '28', key: '28'},
    {name: '29', key: '29'},
    {name: '30', key: '30'},
]


var Header = function (props) {
    const links = [
        {'page':'/president','name':'President','key':'president'},
        {'page':'/ussenate','name':'U.S. Senate', 'key':'ussenate'},
        {'page':'/ushouse','name':'U.S. House', 'key':'ushouse'},
        {'page':'/ssenate','name':'State Senate','key':'ssenate'}
       // {'page':'/shouse','name':'State House', 'key':'shouse'},
    ]
    let history = useHistory();
    var LinkButtons = links.map( (l) => {
        console.log(('/dashboard'+l.page))
        let urlstring = ('/dashboard'+l.page)
       if (urlstring === props.curpath) {
        return ( <Box 
            key={l.page}
            onClick={() => {history.push(urlstring);} }
            borderBottom="2px solid #2580db"
            borderTop="2px solid white"
            as="button"
            h="100%"
            w="fit-content"
            paddingLeft="16px"
            paddingRight="16px"
            whiteSpace="nowrap"
            marginBottom="0px"
            color="#2580db"
            >
                <Box>{l.name}</Box>
            </Box> )
       } else {
        return ( <Box 
            key={l.page}
            onClick={() => {history.push(urlstring);} }
            borderTop="2px solid white"
            borderBottom="rgb(230,230,230) 2px solid"
            as="button"
            h="100%"
            marginBottom="0px"
            w="fit-content"
            paddingLeft="16px"
            whiteSpace="nowrap"
            paddingRight="16px"
            >
                <Center><Box>{l.name}</Box></Center>
            </Box> )
       }

    });
    const location = useLocation()

    const getDefault = (j) => {
        let curQuery = new URLSearchParams(location.search);
        if (curQuery.has(j)) {
            return curQuery.get(j)
        } else {
            return 'all'
        }
    }

    const resolveJurisQuery = (j,id) => {
        let curQuery = new URLSearchParams(location.search);
        if (id!=='all') {
            curQuery.set(j,id)
        } else{
            curQuery.delete(j)
        }

        let urlstring = (location.pathname+'?'+curQuery.toString())
        history.push(urlstring)
    }    

    var LDMenuItems = LDS.map( (l) => { 
        return (<MenuItemOption value={l.key} key={l.key} onClick={() => resolveJurisQuery('ld',l.key)}>{l.name}</MenuItemOption>)
    })
    var CDMenuItems = CDS.map( (c) => { 
        return (<MenuItemOption value={c.key} key={c.key} onClick={() => resolveJurisQuery('cd',c.key)}>{c.name}</MenuItemOption>)
    })
    return (
        <Flex h="3.75rem" borderBottom="rgb(230,230,230) 2px solid">
            <Center p="2" padding=".5rem" marginLeft="16px"  >
                <Heading size="lg">How'd They Win?</Heading>
            </Center>
            <HStack marginBottom="-2px" flexGrow="1">
                {LinkButtons}
            </HStack>
            <Center padding=".5rem">
            <Menu
            boundary={<Box h="300px" />} closeOnSelect={false}>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<SettingsIcon />}
                    variant="ghost" color="#2580db" size="lg" marginRight="16px"
                />
                <MenuList h="60vh" overflowY="scroll">
                <MenuOptionGroup defaultValue={() => getDefault('cd')} title="CD" type="radio">
                    <MenuItemOption onClick={() => resolveJurisQuery('cd','all')} value="all">All</MenuItemOption>
                    {CDMenuItems}
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup title="LD" type="radio" defaultValue={() => getDefault('ld')}>
                    <MenuItemOption onClick={() => resolveJurisQuery('ld','all')} value="all">All</MenuItemOption>
                    {LDMenuItems}
                </MenuOptionGroup>
                </MenuList>
                </Menu>
            </Center>
        </Flex>
    )
}

export default Header

 