import { Box, Center, CircularProgress, Flex, Heading, Text } from "@chakra-ui/react";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    useLocation,
    useParams
} from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from "../utils/auth.js";

const CustomTooltip = ({ active, payload, label }) => {
    const COLOR_MAP = {
        'd': '#2580db' ,
        'r': '#990033',
        'oth': '#009c2f'
    }
    // Calculate Percentages
    if (active && payload && payload.length) {
        var sumVotes = payload.reduce((a,val) => {
            return a+val.value
        }, 0)
        var ChoiceList = payload.map( (p) => { 
            let percent = Math.round(((p.value/sumVotes)*100)).toString()+'%'
            return (
                <Text key={p.name} width="fit-content" color={COLOR_MAP[p.name]}> {`${CHOICE_MAP[p.name]}: ${toCurrency(p.value)} (${percent})`} </Text> 
            )
        })
        return (
        <Box zIndex="1000" bg="white" borderWidth="1px" borderRadius="lg" padding="12px" width="max-content" fontSize="md" >
            {ChoiceList}
        </Box>
        );
    }
    return null;
};

const urlToId = {
    'president':'pres',
    'ussenate':'sen',
    'ushouse':'ush',
    'ssenate':'ssen',
    'shouse':'sh'
}



  const CHOICE_MAP = {
    'yes': 'Yes',
    'no': 'No',
    'dnv': 'Did Not Vote',
    'd': 'Democrat',
    'r':'Republican',
    'oth': 'Third Party/Write In'
}


const tooltipFormatter = (value, name, props) => {
    return [toCurrency(value), CHOICE_MAP[name], ]
}


function toCurrency(numberString) {
    let number = parseFloat(numberString);
    return number.toLocaleString('USD');
}

var CountDNV = function (props) {
    const { raceId } = useParams();
    const token = useAuth();
    const [chartData,setChartData] = useState([])
    const location = useLocation();

    // Get chart data.
    useEffect(()=>{
        let curQuery = new URLSearchParams(location.search);
        let urlstring = process.env.NODE_ENV === 'production' ? 'https://'+process.env.REACT_APP_BACKEND+'getVotes' : 'http://'+process.env.REACT_APP_BACKEND+'getVotes'
        axios.get(urlstring, {
            headers: {
                'Authorization': `Bearer ${token.token}`
            },
            params: {
                'race': urlToId[raceId],
                'cd': curQuery.has('cd') ? curQuery.get('cd') : 'all',
                'ld': curQuery.has('ld') ? curQuery.get('ld') : 'all',
                'type':'getdnv'
            },
            })
            .then((res) => {
                console.log(res.data)
                res.data = res.data.filter(r=>r.r>0 ? true : false)
                setChartData(res.data.sort((a,b) => (a.count_dem > b.count_dem) ? 1 : ((b.count_dem > a.count_dem) ? -1 : 0)))
            })
            .catch((error) => {
                console.error(error)
            })
    },[raceId,location,token.token])
    return (
        <Box h="100%" w="100%">
            {chartData ? chartData.length===0 ? 
            <Center  h="100%" w="100%"><CircularProgress isIndeterminate color="green.300" /></Center> :
            <Flex 
            flexDirection="column"
            flexGrow="1"
            flexBasis="auto"
            h="100%"
            w="100%">
            <Heading size="md" margin="12px" marginLeft="64px" >Vote Share by Number of Races Not Voted In (Excluding Props)</Heading>
            <Box flexGrow="1"
                flexBasis="auto">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                data={chartData}
                stackOffset="expand"
                >
                    <XAxis dataKey="count_dem" type="category" domain={[0,1,2,3,4]}/>
                    <YAxis tickFormatter={(val) => {return (val*100).toString()+'%'} } />
                    <Tooltip wrapperStyle={{zIndex: 20}} formatter={tooltipFormatter} content={<CustomTooltip />}/>
                    <Bar dataKey="d" stackId="a" fill='#2580db' />
                    <Bar dataKey="oth" stackId="a" fill="#009c2f" />
                    <Bar dataKey="r" stackId="a" fill='#990033' />
                </BarChart>
            </ResponsiveContainer></Box></Flex> : <Center  h="100%" w="100%"><CircularProgress isIndeterminate color="green.300" /></Center>
            }
        </Box>
    )
}

export default CountDNV